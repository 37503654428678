<template>
  <div
    v-if="region"
    class="sm:min-h-svh !px-0 sm:py-base-3 !max-w-none relative grid place-items-center"
  >
    <div
      id="video"
      class="relative aspect-video sm:aspect-auto sm:absolute left-0 top-0 object-cover w-full h-full -z-10 bg-black overflow-hidden pointer-events-none"
    >
      <img
        :src="`https://vumbnail.com/${video}.jpg`"
        class="z-[1]"
        alt="Werkgeluk in de Regio"
      />
      <iframe
        title="vimeo-player"
        :src="`https://player.vimeo.com/video/${video}?background=1`"
        frameborder="0"
        allowfullscreen
        class="z-[2] aspect-video sm:aspect-auto"
      ></iframe>
    </div>

    <div
      class="bg-white w-full sm:w-auto px-4 py-base sm:px-base sm:rounded fk-prose"
    >
      <!-- Heading 1 because of SEO importance-->
      <h1 class="!mb-base-1/2">
        {{ $t("block.searchVacancies.heading") }}
        <div class="text-[1.5em]">{{ region.attributes.municipality }}</div>
      </h1>
      <FormKit type="form" autocomplete="off" :actions="false" @submit="search">
        <FormKit
          type="text"
          name="searchTerms"
          :label="$t(`form.searchTerms.label`)"
          :placeholder="$t(`form.searchTerms.placeholder`)"
          validation-visibility="blur"
        />

        <div
          class="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4"
        >
          <FormKit
            type="submit"
            outer-class="!m-0 sm:order-2"
            :label="$t(`form.searchVacanciesForm.submit.label`)"
          />
          <NuxtLink :to="localePath(`/vacatures`)" class="btn btn-link">
            {{ $t("block.searchVacancies.viewAllVacancies") }}
          </NuxtLink>
        </div>
      </FormKit>
    </div>
    <!-- Scroll indicator-->
    <div class="absolute inset-x-0 flex justify-center bottom-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
        class="animate-bounce w-3 fill-white"
      >
        <path
          d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiBlock } from "@flyingkiwi/nuxt-components";
import type { FormKitNode } from "@formkit/core";

const { region, video } = useStaticData();
const localePath = useLocalePath();
defineProps<StrapiBlock>();

/* eslint-disable @typescript-eslint/no-explicit-any */
const search = async (data: any, node: FormKitNode | undefined) => {
  const { clearFilters, applyFilter } = useVacancyFilters();
  node?.clearErrors();

  clearFilters();
  applyFilter(data.searchTerms, "search");
};
</script>

<style lang="css" scoped>
#video img,
#video iframe {
  @apply absolute top-0 left-0 w-full sm:h-[56.25vw] sm:w-screen sm:left-1/2 sm:top-1/2 sm:absolute sm:min-h-screen sm:min-w-[177.77vh] sm:-translate-x-1/2 sm:-translate-y-1/2;
}
</style>
